import React, { useState, useEffect} from 'react';
import { Box, Typography, Grid,  ThemeProvider, createTheme } from "@mui/material";
import { Progress } from './progress';
import { get_info_stat } from '../../../actions';

const today = new Date();

const curYear = today.getFullYear();

//const curYear = 2022;


const theme = createTheme({
    palette: {
        text: {
          primary: "#000000",
          secondary: "#46505A",
          positive: "#41C07C",
        },
        primary: {
          main: "rgba(255, 215, 47, 1)",
          darker: "#fff",
        },
        neutral: {
          main: "#64748B",
          contrastText: "#fff",
        },
    },
    components:{
      MuiGrid: {
        variants: [
          {
            props: {
              variant: "rel",
            },
            style: {
              position: "relative",
            },
          },
        ],
      },
      MuiTypography: {
        variants: [
          {
            props: {
              variant: "h4",
            },
            style: {
              fontSize: 24,
              lineHeight: "28px",
              fontWeight: 700,
              paddingLeft: 12,
              borderLeft: "solid 5px rgba(255, 215, 47, 1)",
            },
          },
          {
            props: {
              variant: "h5",
            },
            style: {
              fontSize: 22,
              lineHeight: "25px",
              fontWeight: 700,
              marginBottom: 20,
              marginTop: 20,
            },
          },
          {
            props: {
              variant: "h4-thin",
            },
            style: {
              fontSize: 24,
              lineHeight: "28px",
              paddingLeft: 12,
              fontWeight: 300,
            },
          }
        ],
      }
      
    }
  });


export function StatisticBlock(props){

    const [progress, setProgress] = React.useState(10);

    const [data_stat, setDataStat] = React.useState(null);
  
    const initData = async () => { setDataStat(await get_info_stat(curYear)) };
  
    React.useEffect(()=>{
      if(data_stat == null) initData();
    },[]);
  
    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
        1  
        //prevProgress >= 1 ? 0 : prevProgress + 0.1
        );
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []);


    return(
        <ThemeProvider theme={theme}>
        <Box sx={{ marginBottom: "50px" }}>
        <Typography component='h2' variant='h4'>
          СТАТИСТИКА розгляду поданих квартальних фінансових звітів
        </Typography>
        <Typography component='span' variant='h4-thin'>
          (станом на {today.toLocaleDateString('uk')})
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6} padding={2}>
            <Typography component='h4' variant='h5'>
              {curYear - 1}
            </Typography>
            {[1,2,3,4].map(kv=><Progress  year={curYear - 1} accepts={data_stat && data_stat[curYear - 1] && data_stat[curYear - 1][kv] && data_stat[curYear - 1][kv].accepts || 0} finished={data_stat && data_stat[curYear - 1] && data_stat[curYear - 1][kv] && data_stat[curYear - 1][kv].finished || 0} kv={kv} progress={progress}/>)}
          </Grid>
          <Grid item xs={12} sm={6} padding={2}>
            <Typography component='h4' variant='h5'>
              {curYear}
            </Typography>
            {[1,2,3,4].map(kv=><Progress year={curYear} accepts={data_stat && data_stat[curYear] && data_stat[curYear][kv].accepts || 0} finished={data_stat && data_stat[curYear] && data_stat[curYear][kv].finished || 0} kv={kv} progress={progress}/>)}
          </Grid>
        </Grid>
      </Box>
      </ThemeProvider>
    )

}